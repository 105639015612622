<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Claude API"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <validation-observer ref="rulesAssignTo">
            <b-row>
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Nome do produto:"
                    label-for="created_at_from"
                  >
                    <b-form-input
                      id="api_claude_name"
                      v-model="runtimeFilters.name"
                      autocomplete="off"
                    />
                    <small>Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do Claude.</small>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="2">
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  class="mt-2"
                  @click="getApiClaude()"
                >
                  Buscar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row>
            <b-col cols="12">
              <b-spinner
                v-if="isLoading"
                label="Loading..."
              />
              <div v-else>
                <json-viewer
                  :value="results"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></json-viewer>
              </div>
            </b-col>
          </b-row>

        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { regex } from '@validations'
import toast from '@/mixins/toast'
import SusiService from '@/services/susiService'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    BBreadcrumb,
    BFormInput,
    BButton,
    BCardActions,
    BCol,
    BFormGroup,
    BRow,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    JsonViewer,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      regex,

      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Testes Diversos',
          active: true,
        },
      ],
      runtimeFilters: {
        name: '',
      },
      isLoading: false,
      results: [],
    }
  },
  created() {
  },
  methods: {
    reset() {
      this.runtimeFilters = Object.fromEntries(Object.entries(this.runtimeFilters).map(item => {
        // eslint-disable-next-line no-param-reassign
        item[1] = ''
        return item
      }))
    },
    getApiClaude() {
      this.$refs.rulesAssignTo.validate().then(success => {
        if (success) {
          this.isLoading = true

          SusiService.getClaudeCategory(this.runtimeFilters.name)
            .then(response => {
              this.results = response.data.data
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .text-danger{
      font-weight: bold;
  }
</style>
